import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const DatenschutzPage = () => (
  <Layout>
    <div class="page-narrow">
      <SEO title="datenschutz" />
      <h1 className="heading">Datenschutz</h1>

      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung. Die Nutzung unserer Website ist in der Regel ohne
        Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder
        E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
        freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
        Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass
        die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor
        dem Zugriff durch Dritte ist nicht möglich.
      </p>

      <h2 className="heading">Kontaktanfragen</h2>
      <p>
        Wenn Sie uns direkt kontaktieren, werden Ihre Angaben inklusive der von
        Ihnen angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für
        den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
        nicht ohne Ihre Einwilligung weiter.
      </p>

      <h2 className="heading">Recht auf Auskunft, Löschung, Sperrung</h2>
      <p>
        Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre
        gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und
        den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung,
        Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum
        Thema personenbezogene Daten können Sie sich jederzeit unter der im
        Impressum angegebenen Adresse an uns wenden.
      </p>

      <h2 className="heading">Widerspruch Werbe-Mails</h2>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
        Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
      </p>

      <p>Quelle: eRecht24</p>
    </div>
  </Layout>
)

export default DatenschutzPage
